import { createTheme } from '@mui/material/styles';
import { purple, yellow } from '@mui/material/colors';

let theme = createTheme({
  typography: {
    fontFamily: [
      'Alegreya Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

const customTheme = createTheme(theme, {
  palette: {
    primary: {
      main: purple[900],
      light: purple['A700'],
      dark: purple[900],
    },
    secondary: {
      main: yellow[500],
      light: yellow['A200'],
      dark: yellow[900],
    },
  },
  spacing: (multiplier) => `${4 * multiplier}px`,
});

const customGlobalCSS = {
  animations: {
    scaleIcon: {
      transition: 'all 0.3s',

      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.4)',
      },
    },
    scaleText: {
      transition: 'all 0.3s',

      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.05)',
      },
    },
    translateY: {
      transition: 'all 0.3s',

      '&:hover': {
        transform: 'translateY(-3px)',
      },
    },
  },
  background: {
    color: '#F2F6FF',
    linearGradient: `linear-gradient(130deg, ${customTheme.palette.primary.dark} 70%, ${customTheme.palette.primary.light} 120%)`,
    reverseLinearGradient: `linear-gradient(130deg, ${customTheme.palette.primary.light} 85%, ${customTheme.palette.primary.dark} 120%)`,
  },
  customShadows: {
    boxShadow1:
      '4px 4px 8px rgba(0, 0, 0, 0.05), 16px 16px 32px rgba(92, 103, 153, 0.25)',
    boxShadow2:
      '0px 0px 4px rgba(0, 0, 0, 0.05), 8px 8px 16px rgba(92, 103, 153, 0.05)',
    textShadow1: '4px 4px 8px rgba(0, 0, 0, 0.15)',
    buttonShadow1:
      '0px 0px 4px rgba(170, 0, 255, 0.05), 16px 16px 32px rgba(170, 0, 255, 0.15)',
    sideBarShadow1:
      '0px 0px 4px rgba(74, 20, 140, 0.05), 8px 8px 16px rgba(74, 10, 140, 0.2)',
  },
};

export { customTheme, customGlobalCSS };
