import {
  ADD_BOOK_TO_CART_SUCCESS,
  DELETE_BOOK_FROM_CART_SUCCESS,
  DELETE_BOOKS_FROM_CART_SUCCESS,
  CHANGE_MINI_CART_STATUS,
  CLEAR_CART_AFTER_CHECKOUT_SUCCESS,
  UPDATE_CART_ITEMS,
} from '../constants/cart.constant';

const initialState = {
  cartItems:
    typeof window !== 'undefined' && localStorage.getItem('camlambooks_cart')
      ? JSON.parse(localStorage.getItem('camlambooks_cart'))
      : [],
  miniCartStatus: 'close',
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BOOK_TO_CART_SUCCESS:
      const cartItems = [...state.cartItems, action.payload];
      localStorage.setItem('camlambooks_cart', JSON.stringify(cartItems));
      return {
        ...state,
        cartItems,
      };
    case DELETE_BOOK_FROM_CART_SUCCESS:
      localStorage.setItem('camlambooks_cart', JSON.stringify(action.payload));
      return {
        ...state,
        cartItems: action.payload,
      };
    case DELETE_BOOKS_FROM_CART_SUCCESS:
      localStorage.setItem('camlambooks_cart', JSON.stringify(action.payload));
      return {
        ...state,
        cartItems: action.payload,
      };
    case CLEAR_CART_AFTER_CHECKOUT_SUCCESS:
      localStorage.removeItem('camlambooks_cart');
      return {
        ...state,
        cartItems: [],
      };
    case CHANGE_MINI_CART_STATUS:
      return {
        ...state,
        miniCartStatus: action.payload,
      };
    case UPDATE_CART_ITEMS:
      localStorage.setItem(
        'camlambooks_cart',
        JSON.stringify(action.payload.data)
      );
      return {
        ...state,
        cartItems: action.payload.data,
      };
    default:
      return state;
  }
};
