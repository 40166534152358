import router from "next/router";

import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_OUT_SUCCESS,
  GET_ME_SUCCESS,
  GET_ME_FAIL,
} from "../constants/auth.constant";

import {
  changeModalStatus,
  changeAppStatus,
  changeNotificationInfo,
} from "./general.action";

import { UserService, UserAuthService } from "../../services/allApiServices";
import { getUsernameFromEmail } from "../../services/utils";

const AuthService = new UserAuthService();
const User = new UserService();

const signIn = (userSignInData) => async (dispatch) => {
  const { data } = await AuthService.signIn(userSignInData);

  if (data.status === "success") {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: data,
    });
    dispatch(changeModalStatus("close"));
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "success",
        message: `Xin chào, ${getUsernameFromEmail(data.data.user.email)}!`,
      })
    );
  } else {
    dispatch({
      type: SIGN_IN_FAIL,
      payload: data,
    });
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "error",
        message: data.message.includes("Incorrect")
          ? "Không tìm thấy email/mật khẩu này!"
          : "Có lỗi xảy ra!",
      })
    );
  }
};

const signInWithGoogle = (code) => async (dispatch) => {
  const { data } = await AuthService.signInWithGoogle({
    code,
  });

  if (data.status === "success") {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: data,
    });
    dispatch(changeModalStatus("close"));
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "success",
        message: `Xin chào, ${getUsernameFromEmail(data.data.user.email)}!`,
      })
    );
  } else {
    dispatch({
      type: SIGN_IN_FAIL,
      payload: data,
    });
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "error",
        message: data.message.includes("duplicate")
          ? "Email này đã được đăng ký! Vui lòng đăng nhập!"
          : "Có lỗi xảy ra!",
      })
    );
  }
};

const signUp = (userSignUpData) => async (dispatch) => {
  const { data } = await AuthService.signUp(userSignUpData);

  if (data.status === "success") {
    dispatch({
      type: SIGN_UP_SUCCESS,
      payload: data,
    });
    dispatch(changeModalStatus("close"));
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "success",
        message: `Xin chào, ${getUsernameFromEmail(data.data.user.email)}!`,
      })
    );
  } else {
    dispatch({
      type: SIGN_UP_FAIL,
      payload: data,
    });
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "error",
        message:
          (data.message.includes("Email") && "Vui lòng điền email!") ||
          (data.message.includes("Password") && "Vui lòng điền mật khẩu!") ||
          (data.message.includes("duplicate") && "Email này đã tồn tại!") ||
          "Có lỗi xảy ra!",
      })
    );
  }
};

const signOut = (currentUser) => (dispatch) => {
  dispatch({
    type: SIGN_OUT_SUCCESS,
  });
  dispatch(
    changeNotificationInfo({
      status: "open",
      type: "success",
      message: `Tạm biệt, ${getUsernameFromEmail(currentUser.email)}!`,
    })
  );
};

const forgotPassword = (email) => async (dispatch) => {
  const { data } = await AuthService.forgotPassword({ email });
  if (data.status === "success") {
    dispatch(changeModalStatus("close"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "success",
        message: "Email đặt lại mật khẩu đã được gửi cho bạn!",
      })
    );
  } else {
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "error",
        message: data.message.includes("no user")
          ? "Địa chỉ email này không tồn tại!"
          : "Có lỗi xảy ra!",
      })
    );
  }
};

const resetPassword = (password, token) => async (dispatch) => {
  const { data } = await AuthService.resetPassword({ password }, token);
  if (data.status === "success") {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: data,
    });
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "success",
        message: "Đổi mật khẩu thành công!",
      })
    );
    setTimeout(() => {
      router.push("/");
    }, 1000);
  } else {
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "error",
        message: data.message.includes("invalid or has expired")
          ? "Mã token không đúng hoặc đã hết hạn!"
          : "Có lỗi xảy ra!",
      })
    );
    setTimeout(() => {
      router.push("/");
    }, 1000);
  }
};

const checkExistedEmail = (email) => async () => {
  const { data } = await AuthService.checkExistedEmail({ email });
  if (data.status === "success") {
    return data.data === "existed" ? false : true;
  }
};

const getMe =
  ({ meID, token }) =>
  async (dispatch) => {
    const { data } = await User.getOne("me", { id: meID }, token);
    if (data.status === "success") {
      dispatch({
        type: GET_ME_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_ME_FAIL,
        payload: data,
      });
    }
  };

const signInWithApple = (code) => async (dispatch) => {
  const { data } = await AuthService.signInWithApple({
    code,
  });

  if (data.status === "success") {
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: data,
    });
    dispatch(changeModalStatus("close"));
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "success",
        message: `Xin chào, ${getUsernameFromEmail(data.data.user.email)}!`,
      })
    );
  } else {
    dispatch({
      type: SIGN_IN_FAIL,
      payload: data,
    });
    dispatch(changeAppStatus("normal"));
    dispatch(
      changeNotificationInfo({
        status: "open",
        type: "error",
        message: data.message.includes("duplicate")
          ? "Email này đã được đăng ký! Vui lòng đăng nhập!"
          : "Có lỗi xảy ra!",
      })
    );
  }
};

export {
  signIn,
  signInWithGoogle,
  signUp,
  signOut,
  forgotPassword,
  resetPassword,
  checkExistedEmail,
  getMe,
  signInWithApple,
};
