import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { Stack, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { forgotPassword } from '../../redux/actions/auth.action';
import { changeAppStatus } from '../../redux/actions/general.action';

import CustomButton from '../Common/CustomButton.component';
import CustomInput from '../Common/CustomInput.component';

import { validateEmail } from '../../services/utils';

const ForgotPassword = () => {
  const { appStatus } = useSelector((state) => state.general);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
  });

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleSendEmailResetPassword = (data) => {
    dispatch(changeAppStatus('loading'));
    dispatch(forgotPassword(data.email));
  };

  return (
    <form onSubmit={handleSubmit(handleSendEmailResetPassword)} noValidate>
      <Stack spacing={8}>
        <Controller
          autoComplete="email"
          name="email"
          control={control}
          rules={{
            required: true,
            validate: {
              checkIsAnEmail: (email) => validateEmail(email),
            },
          }}
          defaultValue=""
          render={({ field: { onChange, onBlur, value } }) => (
            <CustomInput
              autoFocus
              label="Email"
              required
              onChange={onChange}
              onBlur={() => setTimeout(onBlur, 180)}
              value={value}
              inputProps={{ inputMode: 'email' }}
              helperText="Chúng tôi sẽ gởi link đặt lại mật khẩu vào địa chỉ email này!"
            />
          )}
        />
        {errors.email && errors.email.type === 'required' && (
          <span style={{ color: 'red', marginTop: '8px' }}>
            Vui lòng nhập email!
          </span>
        )}
        {errors.email && errors.email.type === 'checkIsAnEmail' && (
          <span style={{ color: 'red', marginTop: '8px' }}>
            Vui lòng nhập đúng định dạng email!
          </span>
        )}
        <CustomButton
          type="submit"
          variant="contained"
          disabled={appStatus === 'loading'}
        >
          {appStatus === 'loading' ? (
            <>
              <CircularProgress
                color="inherit"
                sx={{
                  height: `${theme.spacing(6)} !important`,
                  width: `${theme.spacing(6)} !important`,
                }}
              />
              <Typography variant="subtitle2" ml={3}>
                Đang gửi email...
              </Typography>
            </>
          ) : (
            'Gửi email'
          )}
        </CustomButton>
      </Stack>
    </form>
  );
};

export default ForgotPassword;
