import {
  CHANGE_APP_STATUS,
  CHANGE_NOTIFICATION_INFO,
  CHANGE_MODAL_STATUS,
  CHANGE_MODAL_CONTENT,
} from '../constants/general.constant';

const changeAppStatus = (appStatus) => async (dispatch) => {
  dispatch({
    type: CHANGE_APP_STATUS,
    payload: appStatus,
  });
};

const changeNotificationInfo = (notificationInfo) => async (dispatch) => {
  dispatch({
    type: CHANGE_NOTIFICATION_INFO,
    payload: notificationInfo,
  });
};

const changeModalStatus = (modalStatus) => async (dispatch) => {
  dispatch({
    type: CHANGE_MODAL_STATUS,
    payload: modalStatus,
  });
};

const changeModalContent = (modalContent) => async (dispatch) => {
  dispatch({
    type: CHANGE_MODAL_CONTENT,
    payload: modalContent,
  });
};

export {
  changeAppStatus,
  changeNotificationInfo,
  changeModalStatus,
  changeModalContent,
};
