import {
  GET_LATEST_RECOMMENDED_BOOKS_SUCCESS,
  GET_LATEST_RECOMMENDED_BOOKS_FAIL,
  GET_LATEST_BOOKS_SUCCESS,
  GET_LATEST_BOOKS_FAIL,
  GET_BOOKS_BY_CATEGORY_SUCCESS,
  GET_BOOKS_BY_CATEGORY_FAIL,
  SEARCH_BOOKS_SUCCESS,
  SEARCH_BOOKS_FAIL,
  GET_BOOK_SUCCESS,
  GET_BOOK_FAIL,
  GET_RANDOM_LEAN_BOOK_SUCCESS,
  GET_RANDOM_LEAN_BOOK_FAIL,
} from "../constants/books.constant";

import { BookService, LeanBookService } from "../../services/allApiServices";
import { changeCartItems } from "./cart.action";

const Book = new BookService();
const LeanBook = new LeanBookService();

const getLatestRecommendedBooks =
  ({ fields, limit, page, sort }) =>
  async (dispatch) => {
    let queryParams = {
      isRecommended: true,
    };

    if (fields) {
      queryParams = { ...queryParams, fields };
    }

    if (limit) {
      queryParams = { ...queryParams, limit };
    }

    if (page) {
      queryParams = { ...queryParams, page };
    }

    if (sort) {
      queryParams = { ...queryParams, sort };
    }

    const { data } = await Book.getAll(queryParams);
    if (data.status === "success") {
      dispatch({
        type: GET_LATEST_RECOMMENDED_BOOKS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_LATEST_RECOMMENDED_BOOKS_FAIL,
        payload: data,
      });
    }
  };

const getLatestBooks =
  ({ fields, limit, page, sort }) =>
  async (dispatch) => {
    let queryParams = {};

    if (fields) {
      queryParams = { ...queryParams, fields };
    }

    if (limit) {
      queryParams = { ...queryParams, limit };
    }

    if (page) {
      queryParams = { ...queryParams, page };
    }

    if (sort) {
      queryParams = { ...queryParams, sort };
    }

    const { data } = await Book.getAll(queryParams);
    if (data.status === "success") {
      dispatch({
        type: GET_LATEST_BOOKS_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_LATEST_BOOKS_FAIL,
        payload: data,
      });
    }
  };

const getBooksByCategory =
  ({ categorySlug, bookID, limit, isRecommended, page, sort }) =>
  async (dispatch) => {
    let queryParams = {
      category: categorySlug,
    };

    if (bookID) {
      queryParams = { ...queryParams, ["_id[ne]"]: bookID };
    }

    if (limit) {
      queryParams = { ...queryParams, limit };
    }

    if (isRecommended) {
      queryParams = { ...queryParams, isRecommended };
    }

    if (page) {
      queryParams = { ...queryParams, page };
    }

    if (sort) {
      queryParams = { ...queryParams, sort };
    }

    const { data } = await Book.getAll(queryParams);

    if (data.status === "success") {
      dispatch({
        type: GET_BOOKS_BY_CATEGORY_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_BOOKS_BY_CATEGORY_FAIL,
        payload: data,
      });
    }
  };

const searchBooks = (searchString) => async (dispatch) => {
  const { data } = await Book.searchAll({ searchString });
  if (data.status === "success") {
    dispatch({
      type: SEARCH_BOOKS_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: SEARCH_BOOKS_FAIL,
      payload: data,
    });
  }
};

const getBook = (id) => async (dispatch) => {
  const { data } = await Book.getOne(id);

  if (data.status === "success") {
    dispatch({
      type: GET_BOOK_SUCCESS,
      payload: data,
    });
    return data.data;
  } else {
    dispatch({
      type: GET_BOOK_FAIL,
      payload: data,
    });
  }
};

const getBookBySlug = (slug) => async (dispatch) => {
  const { data } = await Book.getOne(slug);

  if (data.status === "success") {
    dispatch({
      type: GET_BOOK_SUCCESS,
      payload: data,
    });
    return data.data;
  } else {
    dispatch({
      type: GET_BOOK_FAIL,
      payload: data,
    });
  }
};

const getRefreshBooks = (books) => async (dispatch) => {
  const { data } = await Book.getRefreshBooks({ books });
  dispatch(changeCartItems(data));
};

const getRandomRecommendedBooksByCategory = (category) => async (dispatch) => {
  const { data } = await Book.getRandomRecommendedBooksByCategory(category);
  if (data.status === "success") {
    dispatch({
      type: GET_BOOKS_BY_CATEGORY_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_BOOKS_BY_CATEGORY_FAIL,
      payload: data,
    });
  }
};

const getRandomLeanBook = () => async (dispatch) => {
  const { status, data } = await LeanBook.getRandomLeanBook();
  if (status === 200) {
    dispatch({
      type: GET_RANDOM_LEAN_BOOK_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_RANDOM_LEAN_BOOK_FAIL,
      payload: data,
    });
  }
};

export {
  getLatestRecommendedBooks,
  getLatestBooks,
  getBooksByCategory,
  searchBooks,
  getBook,
  getBookBySlug,
  getRefreshBooks,
  getRandomRecommendedBooksByCategory,
  getRandomLeanBook,
};
