import Script from "next/script";
import { motion } from "framer-motion";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ThemeProvider } from "@mui/material/styles";

import { customTheme, customGlobalCSS } from "../styles/customTheme";

import { wrapper } from "../redux/store";

import Layout from "../components/Layout/Layout.component";
import Notification from "../components/Common/Notification.component";
import MiniCart from "../components/Common/MiniCart.component";
import CustomModal from "../components/Common/CustomModal.component";
import ChatBot from "../components/Common/ChatBot.component";

import "../styles/globals.css";
import "../styles/nprogress.css";

const clientId = process.env.NEXT_PUBLIC_GOOGLE_ID;
const gaMeasurementId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

const MyApp = ({ Component, pageProps, router }) => {
  return (
    <ThemeProvider theme={{ ...customTheme, ...customGlobalCSS }}>
      <GoogleOAuthProvider clientId={clientId}>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}
        />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', ${gaMeasurementId}, {
              page_path: window.location.pathname,
            });
              `,
          }}
        />
        <Layout>
          <Notification />
          <MiniCart />
          <CustomModal />
          <motion.div
            key={router.route}
            initial="initial"
            animate="animate"
            variants={{
              initial: {
                opacity: 0,
              },
              animate: {
                opacity: 1,
              },
            }}
          >
            <Component {...pageProps} />
          </motion.div>
          <ChatBot />
        </Layout>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default wrapper.withRedux(MyApp);
