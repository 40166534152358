import Link from "next/link";

import { Typography, SvgIcon, Grid } from "@mui/material";
import { red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

const FooterContainer = styled("footer")(({ theme }) => ({
  alignItems: "center",
  backgroundImage: theme.background.linearGradient,
  color: theme.palette.common.white,
  display: "flex",
  fontSize: theme.typography.caption.fontSize,
  flexDirection: "column",
  gap: theme.spacing(2),
  justifyContent: "center",
  padding: theme.spacing(3),
  width: "100%",
}));

const FooterLink = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(10),
  justifyContent: "center",

  "& a": {
    ...theme.animations.scaleText,
  },
}));

const FooterText = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid item>
          <a
            href="mailto:camlambooks@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <EmailOutlinedIcon />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.facebook.com/camlambooks"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookOutlinedIcon />
          </a>
        </Grid>
      </Grid>
      <FooterLink>
        <Link href="/">Trang chủ</Link>
        <Link href="/about-us">Về chúng tôi</Link>
        <a
          href="https://www.patreon.com/camlambooks"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#ffeb3b" }}
        >
          Ủng hộ
        </a>
      </FooterLink>
      <FooterText>
        <Typography variant="body">
          &copy; Copyright 2023 - Made with{" "}
          <SvgIcon sx={{ color: red[600], fontSize: "small" }}>
            <FavoriteIcon />
          </SvgIcon>{" "}
          by Hugo Nguyen
        </Typography>
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;
