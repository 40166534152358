import {
  GET_ALL_TESTS_SUCCESS,
  GET_ALL_TESTS_FAIL,
  CLEAR_ERRORS,
} from '../constants/tests.constant';

const initialState = {
  allTests: [],
};

export const testsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TESTS_SUCCESS:
      return {
        ...state,
        allTests: action.payload.data,
      };
    case GET_ALL_TESTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
