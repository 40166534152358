import React, { useEffect } from "react";

const botpressId = process.env.NEXT_PUBLIC_BOTPRESS_ID;

const ChatBot = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.botpressWebChat.init({
        botId: botpressId,
        botName: "Cam Lâm Books",
        botConversationDescription: "Thủ thư AI",
        enableConversationDeletion: true,
        website: "https://camlambooks.com",
        emailAddress: "camlambooks@gmail.com",
        showBotInfoPage: true,
        composerPlaceholder: "Hỏi gì thủ thư AI đi nè!",
        className: "camlambooks-bot",
        coverPictureUrl:
          "https://res.cloudinary.com/dn7ksjnu4/image/upload/v1700191497/others/cover.jpg",
        avatarUrl:
          "https://res.cloudinary.com/dn7ksjnu4/image/upload/v1700191497/others/logo.jpg",
        hostUrl: "https://cdn.botpress.cloud/webchat/v1",
        messagingUrl: "https://messaging.botpress.cloud",
        clientId: botpressId,
        stylesheet:
          "https://webchat-styler-css.botpress.app/prod/code/53ee3fc0-41f9-4e10-b240-e66a0bd7da71/v60183/style.css",
      });
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="webchat" />;
};

export default ChatBot;
