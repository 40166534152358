import { HttpService } from "./httpService";

export class AuthService {
  http;

  constructor(urlPrefix = "") {
    this.http = new HttpService(urlPrefix);
  }

  async signIn(body) {
    return await this.http.post("/signin", body);
  }

  async signInWithGoogle(body) {
    return await this.http.post("/signinWithGoogle", body);
  }

  async signUp(body) {
    return await this.http.post("/signup", body);
  }

  async forgotPassword(email) {
    return await this.http.post("/forgotPassword", email);
  }

  async resetPassword(password, token) {
    return await this.http.patch(`/resetPassword/${token}`, password);
  }

  async checkExistedEmail(email) {
    return await this.http.get("/checkExistedEmail", email);
  }

  async signInWithApple(body) {
    return await this.http.post("/signinWithApple", body);
  }
}
