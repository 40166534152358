import { HttpService } from "./httpService";

export class BaseService {
  http;

  constructor(urlPrefix = "") {
    this.http = new HttpService(urlPrefix);
  }

  async getAll(queryObject, token) {
    return await this.http.get(``, queryObject, token);
  }

  async searchAll(searchString) {
    return await this.http.get(`/search`, searchString);
  }

  async getOne(id, queryObject, token) {
    return await this.http.get(`/${id}`, queryObject, token);
  }

  async getOneBySlug(slug, queryObject, token) {
    return await this.http.get(`/${slug}`, queryObject, token);
  }

  async createOne(body, token) {
    return await this.http.post(``, body, {}, token);
  }

  async updateOne(id, body) {
    return await this.http.patch(`/${id}`, body);
  }

  async deleteOne(id) {
    return await this.http.delete(`/${id}`);
  }
}
