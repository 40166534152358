import Head from "next/head";

const HeadTags = ({
  title = "Thư viện cộng đồng",
  image = "https://res.cloudinary.com/dn7ksjnu4/image/upload/v1638957135/book%20image%20cover/social-image-link_rwyrpu.png",
  description = "Thư viện cộng đồng tại Cam Lâm - Khánh Hoà. Rất nhiều đầu sách hay cho mọi người có thể mượn đọc hoàn toàn miễn phí.",
}) => (
  <Head>
    <title key="title">{`Cam Lâm Books - ${title}`}</title>

    <meta name="description" key="description" content={description} />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <meta charSet="UTF-8" />
    <meta name="theme-color" content="#4a158d" />

    {/* openGraph meta tag to display when share website link */}
    <meta property="og:type" content="website" />
    <meta property="og:image" content={image} />
    <meta property="og:image:type" content="image/png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:image:alt" content="logo" />

    <link rel="icon" href="/favicon.png" sizes="16*16" type="image/png" />
  </Head>
);
export default HeadTags;
