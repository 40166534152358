import { useTheme } from "@emotion/react";
import Image from "next/image";

import { Avatar, Typography } from "@mui/material";

const CustomAvatar = (props) => {
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        bgcolor: theme.palette.secondary.main,
        color: theme.palette.common.black,
        height: theme.spacing(8),
        width: theme.spacing(8),
      }}
    >
      {props?.currentUser?.avatar === "defaultAvatar.jpg" ? (
        <Typography variant="caption" sx={{ height: "18px" }}>
          {props?.currentUser?.email
            .split("")
            .splice(0, 3)
            .join("")
            .toUpperCase()}
        </Typography>
      ) : (
        <Image
          layout="fill"
          src={props?.currentUser?.avatar || "/"}
          alt="user avatar"
        />
      )}
    </Avatar>
  );
};

export default CustomAvatar;
