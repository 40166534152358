import {
  CHANGE_APP_STATUS,
  CHANGE_NOTIFICATION_INFO,
  CHANGE_MODAL_STATUS,
  CHANGE_MODAL_CONTENT,
} from '../constants/general.constant';

const initialState = {
  appStatus: 'normal',
  notificationInfo: {
    status: 'close',
    type: 'success',
    message: '',
  },
  modalStatus: 'close',
  modalContent: {
    title: 'Đăng nhập',
    type: 'signin',
  },
};

export const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };
    case CHANGE_NOTIFICATION_INFO:
      return {
        ...state,
        notificationInfo: action.payload,
      };

    case CHANGE_MODAL_STATUS:
      return {
        ...state,
        modalStatus: action.payload,
        modalContent: {
          title: 'Đăng nhập',
          type: 'signin',
        },
      };
    case CHANGE_MODAL_CONTENT:
      return {
        ...state,
        modalContent: action.payload,
      };
    default:
      return state;
  }
};
