import Cookies from 'js-cookie';

import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAIL,
  GET_ME_SUCCESS,
  GET_ME_FAIL,
} from '../constants/auth.constant';

const initialState = {
  currentUser: Cookies.get('camlambooks_auth')
    ? JSON.parse(Cookies.get('camlambooks_auth'))
    : null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      Cookies.set('camlambooks_auth', JSON.stringify(action.payload.data.user));
      Cookies.set('camlambooks_token', JSON.stringify(action.payload.token));
      return {
        ...state,
        currentUser: action.payload.data.user,
      };
    case SIGN_IN_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case SIGN_UP_SUCCESS:
      Cookies.set('camlambooks_auth', JSON.stringify(action.payload.data.user));
      Cookies.set('camlambooks_token', JSON.stringify(action.payload.token));
      return {
        ...state,
        currentUser: action.payload.data.user,
      };
    case SIGN_UP_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case SIGN_OUT_SUCCESS:
      Cookies.remove('camlambooks_auth');
      Cookies.remove('camlambooks_token');
      return {
        ...state,
        currentUser: null,
      };
    case SIGN_OUT_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case GET_ME_SUCCESS:
      Cookies.set('camlambooks_auth', JSON.stringify(action.payload.data));
      return {
        ...state,
        currentUser: action.payload.data,
      };
    case GET_ME_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
};
