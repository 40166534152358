import { useSelector, useDispatch } from 'react-redux';

import { Snackbar, Alert, Typography } from '@mui/material';
import { changeNotificationInfo } from '../../redux/actions/general.action';

const Notifcation = () => {
  const { notificationInfo } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const handleCloseNotification = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      changeNotificationInfo({ status: 'close', type: 'success', message: '' })
    );
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={2000}
      open={notificationInfo.status === 'open'}
      onClose={handleCloseNotification}
      message={notificationInfo.message}
      key={'top left'}
      sx={{ right: '10px !important', top: '94px !important' }}
    >
      <Alert
        severity={notificationInfo.type || 'success'}
        variant="filled"
        onClose={handleCloseNotification}
      >
        <Typography>{notificationInfo.message}</Typography>
      </Alert>
    </Snackbar>
  );
};

export default Notifcation;
