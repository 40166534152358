export const ADD_BOOK_TO_CART_SUCCESS = 'ADD_BOOK_TO_CART_SUCCESS';

export const DELETE_BOOK_FROM_CART_SUCCESS = 'DELETE_BOOK_FROM_CART_SUCCESS';
export const DELETE_BOOKS_FROM_CART_SUCCESS = 'DELETE_BOOKS_FROM_CART_SUCCESS';

export const CLEAR_CART_AFTER_CHECKOUT_SUCCESS =
  'CLEAR_CART_AFTER_CHECKOUT_SUCCESS';

export const ADD_BOOK_TO_CART_FAIL = 'ADD_BOOK_TO_CART_FAIL';

export const CHANGE_MINI_CART_STATUS = 'CHANGE_MINI_CART_STATUS';

export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
