import { useSelector, useDispatch } from 'react-redux';

import { Modal, Box, Typography, Stack, Fade, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import {
  changeModalStatus,
  changeModalContent,
} from '../../redux/actions/general.action';

import SignIn from '../Auth/SignIn.component';
import SignUp from '../Auth/SignUp.component';
import ForgotPassword from '../Auth/ForgotPassword.component';

const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 8,
  left: '50%',
  position: 'absolute',
  padding: `${theme.spacing(8)} ${theme.spacing(12)} ${theme.spacing(
    10
  )} ${theme.spacing(12)}`,
  transform: 'translate(-50%, -50%)',
  top: '50%',
  width: '400px',

  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(
      6
    )} ${theme.spacing(6)}`,
    width: '90vw',
  },

  '&:focus-visible': {
    outline: 'none',
  },
}));

const CustomModal = () => {
  const { modalStatus, modalContent } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleCloseModal = () => {
    dispatch(changeModalStatus('close'));
  };

  const handleClickBackIconButton = () => {
    dispatch(changeModalContent({ title: 'Đăng nhập', type: 'signin' }));
  };

  return (
    <Modal
      aria-labelledby="modal"
      aria-describedby="modal"
      open={modalStatus === 'open'}
      onClose={handleCloseModal}
      closeAfterTransition
    >
      <Fade in={modalStatus === 'open'}>
        <BoxStyled>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent={
              modalContent.type !== 'signin' ? 'space-between' : 'flex-end'
            }
          >
            {modalContent.type !== 'signin' && (
              <IconButton onClick={handleClickBackIconButton}>
                <ArrowBackIosNewOutlinedIcon color="primary" />
              </IconButton>
            )}
            <IconButton onClick={handleCloseModal}>
              <CloseOutlinedIcon color="primary" />
            </IconButton>
          </Stack>
          <Stack spacing={4}>
            <Stack alignItems="center">
              <Typography
                id="transition-modal-title"
                variant="h5"
                sx={{
                  backgroundImage: theme.background.linearGradient,
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {modalContent.title}
              </Typography>
            </Stack>
            {modalContent.type === 'forgotPassword' ? (
              <ForgotPassword />
            ) : modalContent.type === 'signin' ? (
              <SignIn />
            ) : (
              <SignUp />
            )}
          </Stack>
        </BoxStyled>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
