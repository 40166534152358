import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Link from "next/link";
import Image from "next/image";

import {
  AppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";

import useAuthHook from "../../hooks/auth.hook";

import { changeMiniCartStatus } from "../../redux/actions/cart.action";
import { changeModalStatus } from "../../redux/actions/general.action";

import SearchInput from "../Common/Search.component";
import CustomButton from "../Common/CustomButton.component";
import MyAccountMenu from "../Common/MyAccountMenu.component";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  backgroundImage: theme.background.linearGradient,
}));

const MainToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: `${theme.spacing(4)} ${theme.spacing(0)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`,
}));

const NavbarAuthCartContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(1),
}));

const Logo = styled("div")(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  marginRight: theme.spacing(3),

  "&:hover": {
    cursor: "pointer",
  },
  "& > div": {
    height: "52px",
    width: "80px",
  },
}));

const Header = () => {
  const [mounted, setMounted] = useState(false);
  const { miniCartStatus } = useSelector((state) => state.cart);
  const { cartItems } = useSelector((state) => state.cart);
  const { currentUser } = useAuthHook();
  const dispatch = useDispatch();

  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleClickSignInButton = () => {
    dispatch(changeModalStatus("open"));
  };

  const handleOpenMiniCart = () => {
    miniCartStatus === "close" && dispatch(changeMiniCartStatus("open"));
  };

  return (
    mounted && (
      <AppBarStyled position="sticky">
        <MainToolbar>
          <Link href="/" passHref>
            <Logo>
              <Image
                layout="responsive"
                height="100%"
                width="100%"
                sizes="50vw"
                src="/static/images/logo.svg"
                alt="Cam Lâm Books logo"
                loading="lazy"
              />
            </Logo>
          </Link>
          <SearchInput />
          <NavbarAuthCartContainer>
            {currentUser ? (
              <MyAccountMenu />
            ) : (
              <>
                <CustomButton
                  variant="text"
                  color="inherit"
                  onClick={handleClickSignInButton}
                >
                  <Typography variant={matchesXS ? "caption" : "subtitle2"}>
                    Đăng nhập
                  </Typography>
                  <LoginOutlinedIcon />
                </CustomButton>
              </>
            )}
            {!matchesSM && (
              <Link href="/cart" passHref>
                <Badge
                  badgeContent={cartItems?.length}
                  showZero
                  max={10}
                  color="secondary"
                  sx={{
                    "&:hover": { cursor: "pointer" },
                    "& span": { color: "#000" },
                  }}
                  style={{ marginRight: "2px" }}
                >
                  <ShoppingCartOutlinedIcon onMouseEnter={handleOpenMiniCart} />
                </Badge>
              </Link>
            )}
          </NavbarAuthCartContainer>
        </MainToolbar>
      </AppBarStyled>
    )
  );
};

export default Header;
