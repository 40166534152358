import { DOCS_PER_PAGE } from './constants';

const removeAscent = (str) => {
  // format Vietnamese text
  if (str === null || str === undefined) return str;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  return str;
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validateOnlyTextandSpace = (text) => {
  const re = /^[a-zA-Z ]*$/;
  return re.test(removeAscent(text));
};

const validateOnlyNumber = (number) => {
  const re = /^[0-9]*$/;
  return re.test(number);
};

const validateVNPhoneNumber = (phoneNumber) => {
  const re =
    /^(0|\+84)(\s|\.)?((3[2-9])|(5[689])|(7[06-9])|(8[1-689])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$|^$/;
  return re.test(phoneNumber);
};

const getUsernameFromEmail = (email) => {
  return email?.split('@')[0];
};

const calculateTotalPageByTotalDocs = (totalDocs) => {
  const totalPage = Math.ceil(totalDocs / DOCS_PER_PAGE);
  return totalPage;
};

const convertISODateStringToDate = (ISODateString) => {
  const date = new Date(ISODateString);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }

  const resultDate = `${day}/${month}/${year}`;
  return resultDate;
};

const skeletonArray = Array.from(Array(DOCS_PER_PAGE));

export {
  validateEmail,
  validateOnlyTextandSpace,
  validateOnlyNumber,
  validateVNPhoneNumber,
  getUsernameFromEmail,
  calculateTotalPageByTotalDocs,
  convertISODateStringToDate,
  skeletonArray,
};
