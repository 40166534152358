import { useRouter } from "next/router";
import { useGoogleLogin } from "@react-oauth/google";

import { Stack } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

import CustomButton from "../../components/Common/CustomButton.component";

const redirectURI = process.env.NEXT_PUBLIC_GOOGLE_REDIRECT_URI;

const GoogleSignIn = () => {
  const router = useRouter();

  const handleSignIn = useGoogleLogin({
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: redirectURI,
    state: router.asPath,
  });

  return (
    <Stack alignItems="center">
      <CustomButton
        onClick={handleSignIn}
        variant="outlined"
        startIcon={<GoogleIcon />}
      >
        Đăng nhập bằng Google
      </CustomButton>
    </Stack>
  );
};

export default GoogleSignIn;
