import axios from "axios";

import { BaseService } from "./baseService";
import { AuthService } from "./authService";

const STRAPI_API_URL = process.env.STRAPI_API_URL;

class TestService extends BaseService {
  constructor() {
    super("tests");
  }
}

class BookService extends BaseService {
  constructor() {
    super("books");
  }

  async getRefreshBooks(body) {
    return await this.http.post(`/refreshBooks`, body);
  }

  async getRandomRecommendedBooksByCategory(category) {
    return await this.http.get(`/getRandomRecommendBooksByCategory`, category);
  }
}

class CategoryService extends BaseService {
  constructor() {
    super("categories");
  }
}

class UserService extends BaseService {
  constructor() {
    super("users");
  }
}

class OrderService extends BaseService {
  constructor() {
    super("orders");
  }
}

class ReviewService extends BaseService {
  constructor() {
    super("reviews");
  }
}

class UserAuthService extends AuthService {
  constructor() {
    super("users");
  }
}

class LeanBookService extends BaseService {
  constructor() {
    super("leanbook");
  }

  async getRandomLeanBook() {
    return await axios.get(`${STRAPI_API_URL}/books/random-book`);
  }
}

export {
  TestService,
  BookService,
  CategoryService,
  UserService,
  OrderService,
  ReviewService,
  UserAuthService,
  LeanBookService,
};
