import { useState } from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { useTheme } from "@emotion/react";
import { googleLogout } from "@react-oauth/google";

import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import useAuthHook from "../../hooks/auth.hook";

import CustomButton from "./CustomButton.component";
import CustomAvatar from "./CustomAvatar.component";

import { signOut } from "../../redux/actions/auth.action";

import { getUsernameFromEmail } from "../../services/utils";

const MyAccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { currentUser } = useAuthHook();
  const dispatch = useDispatch();

  const theme = useTheme();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(signOut(currentUser));
    googleLogout();
  };

  return (
    <div>
      <CustomButton
        id="my-account-menu-button"
        aria-controls="my-account-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
        variant="text"
        color="inherit"
      >
        <CustomAvatar currentUser={currentUser} />
        <KeyboardArrowDownIcon />
      </CustomButton>
      <Menu
        id="my-account-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: theme.customShadows.boxShadow1,
            minWidth: "200px",
          },
        }}
      >
        <MenuItem sx={{ display: "none" }} />
        <MenuItem
          sx={{
            pointerEvents: "none",
          }}
        >
          <Typography>Xin chào, &nbsp;</Typography>
          <Typography fontWeight="bold" color="primary">
            {getUsernameFromEmail(currentUser?.email)}
          </Typography>
        </MenuItem>
        <Divider />
        <Link href="/profile" passHref>
          <MenuItem onClick={handleCloseMenu}>
            <Typography color="primary">Thông tin cá nhân</Typography>
          </MenuItem>
        </Link>
        <Link href="/order-history" passHref>
          <MenuItem onClick={handleCloseMenu}>
            <Typography color="primary">Lịch sử mượn sách</Typography>
          </MenuItem>
        </Link>
        <MenuItem onClick={handleSignOut}>
          <Typography color="primary">Thoát tài khoản</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MyAccountMenu;
