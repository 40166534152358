import {
  GET_LATEST_RECOMMENDED_BOOKS_SUCCESS,
  GET_LATEST_RECOMMENDED_BOOKS_FAIL,
  GET_LATEST_BOOKS_SUCCESS,
  GET_LATEST_BOOKS_FAIL,
  GET_BOOKS_BY_CATEGORY_SUCCESS,
  GET_BOOKS_BY_CATEGORY_FAIL,
  SEARCH_BOOKS_SUCCESS,
  SEARCH_BOOKS_FAIL,
  GET_BOOK_SUCCESS,
  GET_BOOK_FAIL,
  GET_RANDOM_LEAN_BOOK_FAIL,
  GET_RANDOM_LEAN_BOOK_SUCCESS,
} from "../constants/books.constant";

const initialState = {
  latestRecommendedBooks: {
    totalDocs: 0,
    books: [],
  },
  latestBooks: {
    totalDocs: 0,
    books: [],
  },
  booksByCategory: {
    totalDocs: 0,
    books: [],
  },
  book: null,
  searchBookResults: [],
  leanBook: null,
};

export const booksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LATEST_RECOMMENDED_BOOKS_SUCCESS:
      return {
        ...state,
        latestRecommendedBooks: {
          totalDocs: action.payload.totalDocs,
          books: action.payload.data,
        },
      };
    case GET_LATEST_RECOMMENDED_BOOKS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_LATEST_BOOKS_SUCCESS:
      return {
        ...state,
        latestBooks: {
          totalDocs: action.payload.totalDocs,
          books: action.payload.data,
        },
      };

    case GET_LATEST_BOOKS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_BOOKS_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        booksByCategory: {
          totalDocs: action.payload.totalDocs,
          books: action.payload.data,
        },
      };

    case GET_BOOKS_BY_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case SEARCH_BOOKS_SUCCESS:
      return {
        ...state,
        searchBookResults: action.payload.data,
      };

    case SEARCH_BOOKS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_BOOK_SUCCESS:
      return {
        ...state,
        book: action.payload.data,
      };

    case GET_BOOK_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case GET_RANDOM_LEAN_BOOK_SUCCESS:
      return {
        ...state,
        leanBook: action.payload,
      };

    case GET_RANDOM_LEAN_BOOK_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
};
