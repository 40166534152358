import { useSelector } from "react-redux";
import nprogress from "nprogress";
import Router, { useRouter } from "next/router";

import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import Header from "./Header.component";
import Footer from "./Footer.component";
import HeadTags from "./HeadTags.component";
import Overlay from "./Overlay.component";
import BottomNav from "./BottomNav.component";

const LayoutContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.background.color,
}));

const MainContainer = styled("div")(({ theme }) => ({
  minHeight: "85vh",
  padding: theme.spacing(12),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(8),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4),
  },
}));

const titleObj = {
  "/recommended-books": "Khuyên đọc",
  "/books": "Sách mới",
  "/categories": "Thể loại",
  "/": "Thư viện cộng đồng",
};

const Layout = ({ children }) => {
  const { appStatus } = useSelector((state) => state.general);
  const { book, leanBook } = useSelector((state) => state.books);
  const { allCategories } = useSelector((state) => state.categories);
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const router = useRouter();

  nprogress.configure({ parent: "header" });

  Router.onRouteChangeStart = () => nprogress.start();
  Router.onRouteChangeComplete = () => nprogress.done();
  Router.onRouteChangeError = () => nprogress.done();

  const title =
    titleObj[router.route] ||
    allCategories.find((category) => category.slug === router.query.slug)
      ?.title;

  return (
    <LayoutContainer>
      <HeadTags
        title={title || book?.title || leanBook?.title}
        image={leanBook?.imageCover?.url || book?.imageCover}
        description={book?.description || leanBook?.description}
      />
      <Header />
      {appStatus === "searching" && <Overlay />}
      <MainContainer>{children}</MainContainer>
      {matchesSM ? <BottomNav /> : <Footer />}
    </LayoutContainer>
  );
};

export default Layout;
