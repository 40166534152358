import { styled } from '@mui/material/styles';

const OverlayContainer = styled('div')(() => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 100,
}));

const Overlay = () => {
  return <OverlayContainer />;
};

export default Overlay;
