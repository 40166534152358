import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Link from "next/link";
import router from "next/router";
import Image from "next/image";

import { styled, useTheme } from "@mui/material/styles";
import { Stack, Typography, ClickAwayListener, Box } from "@mui/material";

import useAuthHook from "../../hooks/auth.hook";

import { changeMiniCartStatus } from "../../redux/actions/cart.action";

import CustomButton from "./CustomButton.component";

import { getUsernameFromEmail } from "../../services/utils";

const StackStyled = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 4,
  boxShadow: theme.shadows.boxShadow1,
  padding: `${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(
    4
  )} ${theme.spacing(4)} `,
  minHeight: theme.spacing(38),
  maxHeight: theme.spacing(100),
  opacity: "0",
  position: "fixed",
  pointerEvents: "none",
  right: "0px",
  top: "85px",
  transition: "all 0.3s",
  width: theme.spacing(75),
  zIndex: 99,

  "&.open": {
    opacity: "1",
    pointerEvents: "auto",
  },
}));

const ViewCartButtonContainer = styled("div")(({ theme }) => ({
  background: theme.palette.common.white,
  position: "sticky",

  "& button": {
    display: "block",
    width: "100%",
  },
}));

const MiniCart = () => {
  const [mounted, setMounted] = useState(false);
  const { miniCartStatus } = useSelector((state) => state.cart);
  const { cartItems } = useSelector((state) => state.cart);
  const { currentUser } = useAuthHook();

  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleClickViewCartButton = () => {
    dispatch(changeMiniCartStatus());
    router.push("/cart");
  };

  const handleCloseMiniCart = () => {
    dispatch(changeMiniCartStatus("close"));
  };

  return (
    mounted && (
      <ClickAwayListener onClickAway={handleCloseMiniCart}>
        <StackStyled
          spacing={6}
          onMouseLeave={handleCloseMiniCart}
          className={miniCartStatus === "open" && "open"}
        >
          <Typography
            variant="h6"
            sx={{
              backgroundImage: theme.background.linearGradient,
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Giỏ hàng ({cartItems.length})
          </Typography>
          <Stack spacing={4} sx={{ overflow: "auto" }}>
            {!cartItems.length ? (
              <Typography sx={{ textAlign: "center" }}>
                {`Hình như ${
                  currentUser ? getUsernameFromEmail(currentUser?.email) : "bạn"
                } chưa tìm được sách hay?`}
              </Typography>
            ) : (
              cartItems?.map((item) => (
                <div key={item?._id} style={{ cursor: "pointer" }}>
                  <Link href={`/books/${item.slug}`} passHref>
                    <Stack
                      direction="row"
                      spacing={4}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                      }}
                    >
                      <Box
                        sx={{
                          "& img": {
                            borderRadius: theme.shape.borderRadius * 0.5,
                          },
                        }}
                      >
                        <Image
                          layout="fixed"
                          height="50"
                          width="70"
                          size="50vw"
                          src={
                            `${item.imageCover}` ||
                            "/static/images/defaultBookCover.svg"
                          }
                          alt={item.slug}
                          objectFit="cover"
                        />
                      </Box>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        fontWeight="bold"
                      >
                        {item?.title}
                      </Typography>
                    </Stack>
                  </Link>
                </div>
              ))
            )}
          </Stack>
          {cartItems?.length ? (
            <ViewCartButtonContainer>
              <CustomButton
                variant="contained"
                onClick={handleClickViewCartButton}
              >
                Xem giỏ hàng
              </CustomButton>
            </ViewCartButtonContainer>
          ) : null}
        </StackStyled>
      </ClickAwayListener>
    )
  );
};

export default MiniCart;
