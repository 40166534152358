import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { useTheme } from '@mui/material/styles';
import {
  Stack,
  InputAdornment,
  Typography,
  CircularProgress,
} from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { changeAppStatus } from '../../redux/actions/general.action';
import { checkExistedEmail, signUp } from '../../redux/actions/auth.action';

import CustomButton from '../Common/CustomButton.component';
import CustomInput from '../Common/CustomInput.component';

import { validateEmail } from '../../services/utils';

const SignUp = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);

  const { appStatus } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
  });

  const handleSignUp = (userSignUpData) => {
    dispatch(changeAppStatus('loading'));
    dispatch(signUp(userSignUpData));
  };

  const handleToggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  return (
    <form onSubmit={handleSubmit(handleSignUp)} noValidate>
      <Stack spacing={8}>
        <Stack spacing={4}>
          <Controller
            autoComplete="email"
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                checkIsAnEmail: (email) => validateEmail(email),
                checkExistedEmail: async (email) => {
                  const result = await dispatch(checkExistedEmail(email));
                  result
                    ? setIsEmailAvailable(true)
                    : setIsEmailAvailable(false);

                  return result;
                },
              },
            }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInput
                autoComplete="email"
                autoFocus
                label="Email"
                required
                onChange={onChange}
                onBlur={() => setTimeout(onBlur, 180)}
                value={value}
                inputProps={{ inputMode: 'email' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isEmailAvailable ? (
                        <CheckCircleOutlineOutlinedIcon color="success" />
                      ) : (
                        errors.email && <CancelOutlinedIcon color="error" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.email && errors.email.type === 'required' && (
            <span style={{ color: 'red', marginTop: '8px' }}>
              Vui lòng nhập email!
            </span>
          )}
          {errors.email && errors.email.type === 'checkIsAnEmail' && (
            <span style={{ color: 'red', marginTop: '8px' }}>
              Vui lòng nhập đúng định dạng email!
            </span>
          )}
          {errors.email && errors.email.type === 'checkExistedEmail' && (
            <span style={{ color: 'red', marginTop: '8px' }}>
              Email này đã tồn tại!
            </span>
          )}
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
              minLength: 6,
            }}
            defaultValue=""
            render={({ field: { onChange, onBlur, value } }) => (
              <CustomInput
                autoComplete="password"
                label="Mật khẩu"
                required
                type={isShowPassword ? 'text' : 'password'}
                onChange={onChange}
                onBlur={() => setTimeout(onBlur, 180)}
                value={value}
                helperText="Mật khẩu phải có tối thiểu 6 ký tự."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        onClick={handleToggleShowPassword}
                        color="primary"
                        sx={{ cursor: 'pointer', textTransform: 'uppercase' }}
                      >
                        {isShowPassword ? 'Ẩn' : 'Hiện'}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          {errors.password && errors.password.type === 'required' && (
            <span style={{ color: 'red', marginTop: '8px' }}>
              Vui lòng nhập mật khẩu!
            </span>
          )}
          {errors.password && errors.password.type === 'minLength' && (
            <span style={{ color: 'red', marginTop: '8px' }}>
              Mật khẩu phải có tối thiểu 6 ký tự!
            </span>
          )}
        </Stack>
        <CustomButton
          variant="contained"
          disabled={appStatus === 'loading'}
          type="submit"
        >
          {appStatus === 'loading' ? (
            <>
              <CircularProgress
                color="inherit"
                sx={{
                  height: `${theme.spacing(6)} !important`,
                  width: `${theme.spacing(6)} !important`,
                }}
              />
              <Typography variant="subtitle2" ml={3}>
                Đang đăng ký...
              </Typography>
            </>
          ) : (
            'Đăng ký'
          )}
        </CustomButton>
      </Stack>
    </form>
  );
};

export default SignUp;
