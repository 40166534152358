import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { useTheme } from "@mui/material/styles";
import {
  Stack,
  Typography,
  InputAdornment,
  CircularProgress,
  Divider,
} from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import {
  changeAppStatus,
  changeModalContent,
} from "../../redux/actions/general.action";
import { signIn } from "../../redux/actions/auth.action";

import CustomButton from "../Common/CustomButton.component";
import CustomInput from "../Common/CustomInput.component";
import GoogleSignIn from "./GoogleSignIn.component";

import { validateEmail } from "../../services/utils";

const SignIn = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isRightEmail, setIsRightEmail] = useState(false);
  // const [isIOS, setIsIOS] = useState(false);

  const { appStatus } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const handleSignIn = (userSignInData) => {
    dispatch(changeAppStatus("loading"));
    dispatch(signIn(userSignInData));
  };

  const handleToggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleClickNoAccountButton = () => {
    dispatch(changeModalContent({ title: "Đăng ký", type: "signup" }));
  };

  const handleClickForgotPasswordButton = () => {
    dispatch(
      changeModalContent({ title: "Quên mật khẩu", type: "forgotPassword" })
    );
  };

  // useEffect(() => {
  //   if (navigator.platform) {
  //     let isIOS =
  //       /iPad|iPhone|iPod/.test(navigator?.platform) ||
  //       (navigator?.platform === "MacIntel" && navigator?.maxTouchPoints > 1);
  //     setIsIOS(isIOS);
  //   }
  // }, []);

  return (
    <>
      <GoogleSignIn />
      <Divider>
        <Typography variant="caption">Hoặc tiếp tục bằng</Typography>
      </Divider>
      <form onSubmit={handleSubmit(handleSignIn)} noValidate>
        <Stack spacing={2}>
          <Stack spacing={4}>
            <Controller
              autoComplete="email"
              name="email"
              control={control}
              rules={{
                required: true,
                validate: {
                  checkIsAnEmail: (email) => {
                    const result = validateEmail(email);
                    if (result) {
                      setIsRightEmail(true);
                    } else {
                      setIsRightEmail(false);
                    }
                    return result;
                  },
                },
              }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomInput
                  label="Email"
                  required
                  onChange={onChange}
                  onBlur={() => setTimeout(onBlur, 180)}
                  value={value}
                  inputProps={{ inputMode: "email" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {errors.email ? (
                          <CancelOutlinedIcon color="error" />
                        ) : (
                          isRightEmail && (
                            <CheckCircleOutlineOutlinedIcon color="success" />
                          )
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errors.email && errors.email.type === "required" && (
              <span style={{ color: "red", marginTop: "8px" }}>
                Vui lòng nhập email!
              </span>
            )}
            {errors.email && errors.email.type === "checkIsAnEmail" && (
              <span style={{ color: "red", marginTop: "8px" }}>
                Vui lòng nhập đúng định dạng email!
              </span>
            )}
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
                minLength: 6,
              }}
              defaultValue=""
              render={({ field: { onChange, onBlur, value } }) => (
                <CustomInput
                  autoComplete="password"
                  label="Mật khẩu"
                  required
                  type={isShowPassword ? "text" : "password"}
                  onChange={onChange}
                  onBlur={() => setTimeout(onBlur, 180)}
                  value={value}
                  helperText="Mật khẩu phải có tối thiểu 6 ký tự."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          onClick={handleToggleShowPassword}
                          color="primary"
                          sx={{ cursor: "pointer", textTransform: "uppercase" }}
                        >
                          {isShowPassword ? "Ẩn" : "Hiện"}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {errors.password && errors.password.type === "required" && (
              <span style={{ color: "red", marginTop: "8px" }}>
                Vui lòng nhập mật khẩu!
              </span>
            )}
            {errors.password && errors.password.type === "minLength" && (
              <span style={{ color: "red", marginTop: "8px" }}>
                Mật khẩu phải có tối thiểu 6 ký tự!
              </span>
            )}
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            justifyContent="space-between"
          >
            <CustomButton
              size="small"
              variant="text"
              onClick={handleClickNoAccountButton}
              sx={{ "&:hover": theme.animations.scaleText }}
            >
              <Typography variant="caption">Tôi chưa có tài khoản</Typography>
            </CustomButton>
            <CustomButton
              size="small"
              variant="text"
              onClick={handleClickForgotPasswordButton}
              sx={{ "&:hover": theme.animations.scaleText }}
            >
              <Typography variant="caption">Tôi quên mật khẩu</Typography>
            </CustomButton>
          </Stack>
          <CustomButton
            variant="contained"
            disabled={appStatus === "loading"}
            type="submit"
          >
            {appStatus === "loading" ? (
              <>
                <CircularProgress
                  color="inherit"
                  sx={{
                    height: `${theme.spacing(6)} !important`,
                    width: `${theme.spacing(6)} !important`,
                  }}
                />
                <Typography variant="subtitle2" ml={3}>
                  Đang đăng nhập...
                </Typography>
              </>
            ) : (
              "Đăng nhập"
            )}
          </CustomButton>
        </Stack>
      </form>
    </>
  );
};

export default SignIn;
