import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import router from "next/router";

import { useTheme } from "@mui/material/styles";
import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
  Badge,
} from "@mui/material/";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const bottomNavRoutes = {
  0: "/",
  1: "/recommended-books",
  2: "/categories",
  3: "/cart",
};

const BottomNav = () => {
  const [activeNav, setActiveNav] = useState(0);
  const { cartItems } = useSelector((state) => state.cart);
  const { pathname } = router.router;
  const theme = useTheme();

  const handleChangeBottomNav = (_event, value) => {
    setActiveNav(value);
    router.push(bottomNavRoutes[value]);
  };

  useEffect(() => {
    const activeTab = Object.keys(bottomNavRoutes).find(
      (key) => bottomNavRoutes[key] === pathname
    );
    setActiveNav(+activeTab);
  }, [pathname]);

  return (
    <Paper
      sx={{
        borderRadius: `${theme.spacing(8)} ${theme.spacing(8)} 0 0`,
        position: "fixed",
        bottom: "-1px",
        left: 0,
        right: 0,
        zIndex: 19,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={activeNav}
        onChange={handleChangeBottomNav}
        sx={{
          backgroundImage: theme.background.linearGradient,
          height: theme.spacing(15),
          "& svg, span": {
            color: theme.palette.common.white,
          },
        }}
      >
        <BottomNavigationAction
          label="Trang chủ"
          icon={<HomeOutlinedIcon />}
          sx={{
            borderBottom:
              activeNav === 0 && `2px solid ${theme.palette.secondary.main}`,
            opacity: activeNav !== 0 && "0.6",
            padding: "6px 8px 0",
          }}
        />
        <BottomNavigationAction
          label="Khuyên đọc"
          icon={<ThumbUpAltOutlinedIcon />}
          sx={{
            borderBottom:
              activeNav === 1 && `2px solid ${theme.palette.secondary.main}`,
            opacity: activeNav !== 1 && "0.6",
            padding: "6px 8px 0",
          }}
        />
        <BottomNavigationAction
          label="Thể loại"
          icon={<CategoryOutlinedIcon />}
          sx={{
            borderBottom:
              activeNav === 2 && `2px solid ${theme.palette.secondary.main}`,
            opacity: activeNav !== 2 && "0.6",
            padding: "6px 8px 0",
          }}
        />
        <BottomNavigationAction
          label="Giỏ hàng"
          icon={
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              badgeContent={cartItems?.length}
              max={10}
              color="secondary"
              showZero
              sx={{
                "&:hover": { cursor: "pointer" },
                "& span": { color: "#000" },
              }}
            >
              <ShoppingCartOutlinedIcon />
            </Badge>
          }
          sx={{
            borderBottom:
              activeNav === 3 && `2px solid ${theme.palette.secondary.main}`,
            opacity: activeNav !== 3 && "0.6",
            padding: "6px 8px 0",
          }}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
