import axios from 'axios';

const ROOT_URL = process.env.NEXT_PUBLIC_API_URL;

export class HttpService {
  headers = {};

  constructor(urlPrefix = '') {
    this.urlPrefix = urlPrefix;
  }

  async get(url, queryParams, token) {
    try {
      this.getHeaders(token);
      let mergedApiURL = ROOT_URL + this.getUrl(url);
      queryParams
        ? (mergedApiURL =
            mergedApiURL + '/?' + this.mapQueryParams(queryParams))
        : '';
      const response = await axios.get(mergedApiURL, {
        headers: this.headers,
      });
      return response;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  }

  async post(url, body, queryParams = null, token) {
    try {
      this.getHeaders(token);
      const mergedApiURL =
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams);
      const response = await axios.post(mergedApiURL, body, {
        headers: this.headers,
      });
      return response;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  }

  async patch(url, body, queryParams = null) {
    try {
      const mergedApiURL =
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams);
      const response = await axios.patch(mergedApiURL, body);
      return response;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  }

  async delete(url, queryParams = null) {
    try {
      const mergedApiURL =
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams);
      const response = await axios.delete(mergedApiURL);
      return response;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  }

  getUrl(url) {
    return this.urlPrefix + url;
  }

  getHeaders(token = null) {
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    if (token) {
      this.headers = {
        ...this.headers,
        authorization: `Bearer ${token}`,
      };
    }
  }

  mapQueryParams(queryObject) {
    return queryObject
      ? Object.keys(queryObject)
          .map(function (key) {
            return `&${key}=${queryObject[key]}`;
          })
          .join('')
      : '';
  }
}
