import { combineReducers } from 'redux';

import { testsReducer } from './tests.reducer';
import { generalReducer } from './general.reducer';
import { booksReducer } from './books.reducer';
import { categoriesReducer } from './categories.reducer';
import { cartReducer } from './cart.reducer';
import { authReducer } from './auth.reducer';
import { ordersReducer } from './orders.reducer';
import { reviewsReducer } from './reviews.reducer';

const rootReducer = combineReducers({
  general: generalReducer,
  books: booksReducer,
  categories: categoriesReducer,
  cart: cartReducer,
  auth: authReducer,
  orders: ordersReducer,
  reviews: reviewsReducer,
  tests: testsReducer,
});

export default rootReducer;
