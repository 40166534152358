export const GET_LATEST_RECOMMENDED_BOOKS_SUCCESS =
  "GET_LATEST_RECOMMENDED_BOOKS_SUCCESS";
export const GET_LATEST_RECOMMENDED_BOOKS_FAIL =
  "GET_LATEST_RECOMMENDED_BOOKS_FAIL";

export const GET_LATEST_BOOKS_SUCCESS = "GET_LATEST_BOOKS_SUCCESS";
export const GET_LATEST_BOOKS_FAIL = "GET_LATEST_BOOKS_FAIL";

export const GET_BOOKS_BY_CATEGORY_SUCCESS = "GET_BOOKS_BY_CATEGORY_SUCCESS";
export const GET_BOOKS_BY_CATEGORY_FAIL = "GET_BOOKS_BY_CATEGORY_FAIL";

export const SEARCH_BOOKS_SUCCESS = "SEARCH_BOOKS_SUCCESS";
export const SEARCH_BOOKS_FAIL = "SEARCH_BOOKS_FAIL";

export const GET_BOOK_SUCCESS = "GET_BOOK_SUCCESS";
export const GET_BOOK_FAIL = "GET_BOOK_FAIL";

export const GET_RANDOM_LEAN_BOOK_SUCCESS = "GET_RANDOM_LEAN_BOOK_SUCCESS";
export const GET_RANDOM_LEAN_BOOK_FAIL = "GET_RANDOM_LEAN_BOOK_FAIL";
