import { CREATE_NEW_REVIEW_SUCCESS } from '../constants/reviews.constant';

const initialState = {
  newReviewID: null,
};

export const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_REVIEW_SUCCESS:
      return {
        ...state,
        newReviewID: action.payload.data,
      };
    default:
      return state;
  }
};
