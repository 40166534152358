import {
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAIL,
} from '../constants/orders.constant';

const initialState = {
  allOrders: {
    totalDocs: 0,
    orders: [],
  },
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        allOrders: {
          totalDocs: action.payload.totalDocs,
          orders: action.payload.data,
        },
      };
    case GET_ALL_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload.data,
      };
    default:
      return state;
  }
};
