import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Link from "next/link";
import Image from "next/image";
import router from "next/router";

import Highlighter from "react-highlight-words";

import {
  InputBase,
  Typography,
  Stack,
  useMediaQuery,
  ClickAwayListener,
  InputAdornment,
  CircularProgress,
  Fade,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTheme, styled } from "@mui/material/styles";

import { changeAppStatus } from "../../redux/actions/general.action";
import { searchBooks } from "../../redux/actions/books.action";

const SearchContainer = styled("div")(({ theme }) => ({
  minWidth: theme.spacing(39),
  position: "relative",
  width: theme.spacing(120),
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 30,
  padding: `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(
    2
  )} ${theme.spacing(10)}`,
  width: "100%",
}));

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: "absolute",
  left: theme.spacing(3),
  top: theme.spacing(3),
}));

const SearchResults = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.customShadows.boxShadow1,
  borderRadius: `${theme.shape.borderRadius * 2}px ${
    theme.shape.borderRadius * 2
  }px ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
  color: theme.palette.primary.main,
  maxHeight: theme.spacing(112),
  overflow: "auto",
  padding: `${theme.spacing(4)} ${theme.spacing(1)} ${theme.spacing(
    4
  )} ${theme.spacing(2)}`,
  position: "absolute",
  top: "49px",
  width: "100%",

  [theme.breakpoints.down("sm")]: {
    left: "5%",
    position: "fixed",
    top: "69px",
    width: "90%",
  },

  "& .search-result-row.active-row": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },

  "& .search-result-row:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    cursor: "pointer",
  },
}));

const Search = () => {
  const [searchValue, setSearchValue] = useState("");
  const [activeRowSearchResult, setActiveRowSearchResult] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { appStatus } = useSelector((state) => state.general);
  const { searchBookResults } = useSelector((state) => state.books);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const searchInputRef = useRef(null);

  const handleChangeSearchValue = (event) => {
    if (event.target.value.trim().length < 2) {
      dispatch(changeAppStatus("normal"));
      return;
    }
    dispatch(changeAppStatus("searching"));
    setIsLoading(true);
    setTimeout(() => {
      setSearchValue(event.target.value);
    }, 800);
  };

  const handleClearSearchInput = () => {
    searchInputRef.current.value = "";
    setSearchValue("");
    searchInputRef.current.focus();
  };

  const handleCloseSearchResults = useCallback(() => {
    searchInputRef.current.value = "";
    setSearchValue("");
    searchInputRef.current.blur();
    dispatch(changeAppStatus("normal"));
  }, [searchInputRef, setSearchValue, dispatch]);

  const handleGoToActiveRowSearchResult = useCallback(() => {
    if (searchValue !== "" && searchBookResults.length) {
      setActiveRowSearchResult(0);
      handleCloseSearchResults();
      router.push(`/books/${searchBookResults[activeRowSearchResult].slug}`);
    }
    return;
  }, [
    searchValue,
    searchBookResults,
    handleCloseSearchResults,
    activeRowSearchResult,
  ]);

  const handleGoUpRowSearchResult = useCallback(() => {
    activeRowSearchResult > 0 &&
      setActiveRowSearchResult(activeRowSearchResult - 1);
  }, [activeRowSearchResult]);

  const handleGoDownRowSearchResult = useCallback(() => {
    activeRowSearchResult < searchBookResults.length - 1
      ? setActiveRowSearchResult(activeRowSearchResult + 1)
      : setActiveRowSearchResult(0);
  }, [activeRowSearchResult, searchBookResults]);

  const handleSearchKeyPress = useCallback(
    (event) => {
      switch (event.keyCode) {
        case 13: {
          // Enter key
          handleGoToActiveRowSearchResult();
          break;
        }

        case 27: {
          // Esc key
          handleCloseSearchResults();
          break;
        }

        case 38: {
          // Arrow up key
          event.preventDefault(); // use eventListener 'keydown' with it to prevent input cursor moving
          handleGoUpRowSearchResult();
          break;
        }

        case 40: {
          // Arrow down key
          event.preventDefault(); // use eventListener 'keydown' with it to prevent input cursor moving
          handleGoDownRowSearchResult();
          break;
        }

        default:
          return;
      }
    },
    [
      handleGoToActiveRowSearchResult,
      handleCloseSearchResults,
      handleGoUpRowSearchResult,
      handleGoDownRowSearchResult,
    ]
  );

  useEffect(() => {
    if (searchValue) {
      dispatch(searchBooks(searchValue));
    }
  }, [searchValue, dispatch]);

  useEffect(() => {
    setIsLoading(false);
  }, [searchBookResults]);

  useEffect(() => {
    document.addEventListener("keydown", handleSearchKeyPress);
    return () => document.removeEventListener("keydown", handleSearchKeyPress);
  }, [handleSearchKeyPress]);

  return (
    <ClickAwayListener onClickAway={handleCloseSearchResults}>
      <SearchContainer>
        <SearchInput
          type="text"
          id="search-input"
          placeholder={
            matchesXS
              ? "Bạn tìm sách gì?..."
              : "Bạn tìm sách gì?...(sử dụng tiếng Việt có dấu)"
          }
          inputProps={{ "aria-label": "search" }}
          endAdornment={
            <InputAdornment position="end">
              <Typography
                onClick={handleClearSearchInput}
                color="primary"
                sx={{
                  cursor: "pointer",
                  lineHeight: "0.5",
                  textTransform: "uppercase",
                }}
              >
                {searchValue.length >= 2 &&
                searchInputRef.current.value !== "" ? (
                  !matchesXS ? (
                    "Xoá"
                  ) : (
                    <CloseOutlinedIcon fontSize="small" />
                  )
                ) : null}
              </Typography>
            </InputAdornment>
          }
          inputMode="search"
          onChange={handleChangeSearchValue}
          inputRef={searchInputRef}
        />
        <SearchIconStyled />
        {searchValue !== "" && appStatus === "searching" && (
          <Fade in>
            <SearchResults>
              <Stack spacing={4}>
                {isLoading ? (
                  <Typography textAlign="center">
                    <CircularProgress color="primary" />
                  </Typography>
                ) : !searchBookResults.length ? (
                  <Typography textAlign="center">
                    Rất tiếc, chúng tôi không có sách bạn cần tìm 😢
                  </Typography>
                ) : (
                  <>
                    <Typography>
                      Từ khoá:{" "}
                      <Typography component="span" fontWeight="bold">
                        {`"${searchValue}"`}
                      </Typography>{" "}
                    </Typography>
                    {searchBookResults.map((book, index) => (
                      <div
                        key={book._id}
                        className={`search-result-row ${
                          activeRowSearchResult === index && "active-row"
                        }`}
                        onClick={handleCloseSearchResults}
                      >
                        <Link href={`/books/${book.slug}`} passHref>
                          <Stack direction="row" spacing={4}>
                            <div>
                              <Image
                                layout="fixed"
                                height="50"
                                width="70"
                                size="50vw"
                                src={
                                  `${book.imageCover}` ||
                                  "/static/images/defaultBookCover.svg"
                                }
                                alt=""
                                objectFit="cover"
                              />
                            </div>
                            <Typography
                              variant="subtitle2"
                              color="primary"
                              fontWeight="bold"
                            >
                              <Highlighter
                                highlightStyle={{
                                  backgroundColor: theme.palette.secondary.main,
                                  textDecoration: "underline",
                                }}
                                searchWords={[`${searchValue}`]}
                                autoEscape={true}
                                textToHighlight={book.title}
                              />
                            </Typography>
                          </Stack>
                        </Link>
                      </div>
                    ))}
                  </>
                )}
                {!matchesSM ? (
                  <Typography textAlign="center" variant="caption">
                    🎯 Nhấn phím Esc để thoát tìm kiếm
                  </Typography>
                ) : null}
              </Stack>
            </SearchResults>
          </Fade>
        )}
      </SearchContainer>
    </ClickAwayListener>
  );
};

export default Search;
