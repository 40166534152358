import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const ButtonContainer = styled('div')(({ theme }) => ({
  width: '100%',

  '&:hover button': {
    backgroundImage: theme.background.reverseLinearGradient,
    boxShadow: theme.customShadows.buttonShadow1,
    ...theme.animations.translateY,
  },

  '&:hover button.disabled': {
    backgroundImage: 'none',
    boxShadow: 'none',
  },
}));

const ButtonContainedStyled = styled(Button)(({ theme }) => ({
  backgroundImage: theme.background.linearGradient,
  borderRadius: theme.shape.borderRadius * 30,
  height: theme.spacing(11),
  margin: '0 auto',
  width: '100%',

  '&.disabled': {
    backgroundImage: 'none',
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 30,
  height: theme.spacing(11),

  '&:hover': {
    background: 'none',
  },

  '&.disabled': {
    backgroundImage: 'none',
  },
}));

const CustomButton = (props) => {
  if (props.variant === 'contained') {
    return (
      <ButtonContainer>
        <ButtonContainedStyled
          className={props.disabled && 'disabled'}
          {...props}
        >
          {props.children}
        </ButtonContainedStyled>
      </ButtonContainer>
    );
  }
  return (
    <ButtonStyled className={props.disabled && 'disabled'} {...props}>
      {props.children}
    </ButtonStyled>
  );
};

export default CustomButton;
