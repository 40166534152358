import {
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
} from '../constants/categories.constant';

const initialState = {
  allCategories: [],
  category: null,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: action.payload.data,
      };
    case GET_ALL_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload.data,
      };
    case GET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
};
