import {
  ADD_BOOK_TO_CART_SUCCESS,
  DELETE_BOOK_FROM_CART_SUCCESS,
  DELETE_BOOKS_FROM_CART_SUCCESS,
  CHANGE_MINI_CART_STATUS,
  CLEAR_CART_AFTER_CHECKOUT_SUCCESS,
  UPDATE_CART_ITEMS,
} from '../constants/cart.constant';

import { changeNotificationInfo } from './general.action';

const addBookToCart = (book, playSound) => async (dispatch, getState) => {
  const { cartItems } = getState().cart;
  const isBookAddedToCart = cartItems.find((item) => item._id === book._id);

  if (isBookAddedToCart) {
    dispatch(
      changeNotificationInfo({
        status: 'open',
        type: 'error',
        message: `${book.title} đã có trong giỏ hàng!`,
      })
    );
    playSound('fail');
  } else {
    dispatch({
      type: ADD_BOOK_TO_CART_SUCCESS,
      payload: book,
    });
    dispatch(
      changeNotificationInfo({
        status: 'open',
        type: 'success',
        message: `Đã thêm ${book.title} vào giỏ hàng!`,
      })
    );
    playSound('success');
  }
};

const deleteSingleBookFromCart = (book) => async (dispatch, getState) => {
  const { cartItems } = getState().cart;
  const filteredCartItems = cartItems.filter((item) => item._id !== book._id);

  dispatch({
    type: DELETE_BOOK_FROM_CART_SUCCESS,
    payload: filteredCartItems,
  });
  dispatch(
    changeNotificationInfo({
      status: 'open',
      type: 'success',
      message: `Đã xoá ${book.title} khỏi giỏ hàng!`,
    })
  );
};

const deleteMultipleBooksFromCart = (bookIDs) => async (dispatch, getState) => {
  const { cartItems } = getState().cart;
  const filteredCartItems = cartItems.filter(
    (item) => !bookIDs.includes(item._id)
  );
  dispatch({
    type: DELETE_BOOKS_FROM_CART_SUCCESS,
    payload: filteredCartItems,
  });
  dispatch(
    changeNotificationInfo({
      status: 'open',
      type: 'success',
      message: `Đã xoá ${bookIDs.length} sách khỏi giỏ hàng!`,
    })
  );
};

const clearCartAfterCheckout = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CART_AFTER_CHECKOUT_SUCCESS,
  });
};

const changeMiniCartStatus = (miniCartStatus) => async (dispatch) => {
  dispatch({
    type: CHANGE_MINI_CART_STATUS,
    payload: miniCartStatus,
  });
};

const changeCartItems = (refreshedCartItems) => async (dispatch) => {
  dispatch({
    type: UPDATE_CART_ITEMS,
    payload: refreshedCartItems,
  });
};

export {
  addBookToCart,
  deleteSingleBookFromCart,
  deleteMultipleBooksFromCart,
  clearCartAfterCheckout,
  changeMiniCartStatus,
  changeCartItems,
};
